/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentMode,
  getCurrentMode,
  getActiveProject,
  setClearActiveProject,
  setCanOpenLeftSidePanel,
  setActiveMarker,
  updateFloor,
  updateFloorObjectMap,
} from "../store/slices/setup";
import { MODE } from "../_helpers";
import { buildingService, miscellaneousService } from "../_services";
import { getToken } from "../store/slices/auth";
import { set } from "lodash";
const SetupContext = createContext();
export const SetupProvider = ({ children }) => {
  const dispatch = useDispatch();
  const projectsData = useSelector(getActiveProject);
  const [showSites, setShowSites] = React.useState(
    projectsData && projectsData?.map((_, index) => index === 0)
  );
  const [currentMapTool, setCurrentMapTool] = useState(null);
  const [isFloorSelected, setIsFloorSelected] = useState(false);
  const [zoomLevelMap, setZoomLevelMap] = useState(16);
  const [categories, setCategories] = useState([]);
  const [isSetupModalVisible, setIsSetupModalVisible] = useState(false);
  const [isShowProjectForm, setShowProjectForm] = useState(false);
  const [isProjectFormEdit, setIsProjectFormEdit] = useState(false);
  const [editProject, setEditProject] = useState({});
  const [isSiteFormEdit, setIsSiteFormEdit] = useState(false);
  const [editSite, setEditSite] = useState({});
  const [currentProject, setCurrentProject] = useState({});
  const [currentSite, setCurrentSite] = useState({});
  const [currentBuilding, setCurrentBuilding] = useState({});
  const [currentFloor, setCurrentFloor] = useState({});
  const [structureMapMarkers, setStructureMapMarkers] = useState([]);
  const [floorRacks, setFloorRacks] = useState([]);
  const [floorCabnites, setFloorCabnites] = useState([]);
  const [polygonLatLongTracker, setPolygonLatLongTracker] = useState({});
  const [selectedBuildingMarker, setSelectedBuildingMarker] = useState(null);
  const [directSelectedFloorId, setDirectSelectedFloorId] = useState(null);
  const [isClickOutsideDisabled, setIsClickOutsideDisabled] = useState(false);
  const [buildingMarkerContextMenu, setBuildingMarkerContextMenu] =
    useState(null);
  const [polygonCardData, setPolygonCardData] = React.useState(null);
  const [undoData, setUndoData] = React.useState(null);
  const [polygons, setPolygons] = React.useState([]);
  const [originalMarkerPositions, setOriginalMarkerPositions] = useState({});
  const [activeSiteIndex, setActiveSiteIndex] = React.useState([-1, -1]);
  const [activeBuildingIndex, setActiveBuildingIndex] = React.useState([
    -1, -1, -1,
  ]);
  const [activeFloorIndex, setActiveFloorIndex] = React.useState([
    -1, -1, -1, -1,
  ]);
  const [floorRackSocketItems, setFloorRackSocketItems] = useState([]);
  const [structures, setStructures] = useState([]);
  const [selectedCabnite, setSelectedCabnite] = useState(null);
    const [isRackSelected, setIsRackSelected] = useState(false);
  const [racks, setRacks] = useState([
    {
      image_full_url: "./images/racks/0-48U-rack.svg",
      name: "0-48U-Rack",
    },
  ]);
  const [cabnites, setCabnites] = useState([
    {
      image_full_url: "./images/cabinet.png",
      name: "33U_FCabinet",
    },
    {
      image_full_url: "./images/cabinet.png",
      name: "33U_FCabinet",
    },
    {
      image_full_url: "./images/cabinet.png",
      name: "33U_FCabinet",
    },
    {
      image_full_url: "./images/cabinet.png",
      name: "33U_FCabinet",
    },
  ]);
  const [rackEquipmentSockets, setRackEquipmentSockets] = useState([
    {
      image_full_url: "./images/rack-equipments/1U_24_Port_C5e_RJ45_Panel.svg",
      name: "1U_24_Port_C5e_RJ45_Panel",
    },
    {
      image_full_url: "./images/rack-equipments/1U_24_Port_C6_RJ45_Panel.svg",
      name: "1U_24_Port_C6_RJ45_Panel",
    },
    {
      image_full_url: "./images/rack-equipments/1U_24_Port_C6A_RJ45_Panel.svg",
      name: "1U_24_Port_C6A_RJ45_Panel",
    },
    {
      image_full_url:
        "./images/rack-equipments/1U_24_Port_Smartlink_RJ45_Panel-1.png",
      name: "1U_24_Port_Smartlink_RJ45_Panel-1",
    },
    {
      image_full_url: "./images/rack-equipments/1U_Blank_Panel.svg",
      name: "1U_Blank_Panel",
    },
    {
      image_full_url: "./images/rack-equipments/2U_48_Port_C5e_RJ45_Panel.svg",
      name: "2U_48_Port_C5e_RJ45_Panel",
    },
    {
      image_full_url: "./images/rack-equipments/2U_48_Port_C6_RJ45_Panel.svg",
      name: "2U_48_Port_C6_RJ45_Panel",
    },
    {
      image_full_url: "./images/rack-equipments/2U_48_Port_C6A_RJ45_Panel.svg",
      name: "2U_48_Port_C6A_RJ45_Panel",
    },
  ]);

  const mapRef = useRef(null);
  const token = useSelector(getToken);

  const googleMapsApiKey = useMemo(
    () => "AIzaSyC6oxXm0xvNSLA_GyWPnTfx5ezf7SF0eSw",
    []
  );
  useEffect(() => {
    const fetchStructure = async () => {
      try {
        const response = await miscellaneousService.getStructures(token);
        setStructures(response || []);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchStructure();
  }, []);
  /**
   * save rack object inside the rack
   */
  const updateSelectedRackItems = (selectedCabnite, floorRackSocketWithoutImage) => {
    // Ensure `racks` is an object, if not initialize it.
    const updatedRack = {
      ...selectedCabnite,
      racks: selectedCabnite.racks ? { ...selectedCabnite.racks } : {},
    };
  
    // Initialize rackItems array inside racks if it doesn't already exist
    if (!updatedRack.racks.rackItems) {
      updatedRack.racks.rackItems = [];
    }
  
    // If rackItems are provided in selectedCabnite, push them to the racks.rackItems array
    if (selectedCabnite.rackItems && selectedCabnite.rackItems.length > 0) {
      updatedRack.racks.rackItems.push(...selectedCabnite.rackItems);
    }
  
    // Now, handle updates for rackItems inside racks with new items from floorRackSocketWithoutImage
    updatedRack.racks.rackItems = updatedRack.racks.rackItems.map((rack) => {
      const existingFloorRack = floorRackSocketWithoutImage.find(
        (floorRack) => floorRack.id === rack.id
      );
      return existingFloorRack ? { ...rack, ...existingFloorRack } : rack;
    });
  
    // Add any new items from floorRackSocketWithoutImage that aren't already in rackItems
    floorRackSocketWithoutImage.forEach((floorRack) => {
      if (!updatedRack.racks.rackItems.some((rack) => rack.id === floorRack.id)) {
        updatedRack.racks.rackItems.push(floorRack);
      }
    });
  
    return updatedRack;
  };
  
  /**
   * Save floor racks
   */
  const updateSelectedRack = (selectedCabnite, floorRackWithoutImage) => {
    // Ensure `selectedCabnite` is an array or an object, and spread accordingly
    const updatedRack = Array.isArray(selectedCabnite)
      ? { racks: [...selectedCabnite] }
      : { ...selectedCabnite };

    // Now handle the `racks` property and ensure it's iterable (an array)
    const updatedRacks = Array.isArray(updatedRack.racks)
      ? [...updatedRack.racks]
      : [];
    floorRackWithoutImage.forEach((floorRack) => {
      const existingRackIndex = updatedRacks.findIndex(
        (r) => r.id === floorRack.id
      );
      if (existingRackIndex > -1) {
        updatedRacks[existingRackIndex] = floorRack;
      } else {
        updatedRacks.push(floorRack);
      }
    });
    return { ...updatedRack, racks: updatedRacks[0] };
  };

  /**
   * Use  to save the floor Objects
   */
  useEffect(() => {
    const updateFloorObjects = async () => {
      if (
        floorRacks &&
        floorRacks.length > 0 &&
        currentFloor?.floor_polygon_id
      ) {
        const floorRackSocketWithoutImage = floorRackSocketItems.map(({ image, ...rest }) => ({
          ...rest,
        }));
        const currentCabnite = floorCabnites.find(
          (cab) => cab.id === selectedCabnite?.id
        );
        if (!currentCabnite) {
          return;
        }
        const updatedCabnite = updateSelectedRackItems(
          currentCabnite,
          floorRackSocketWithoutImage
        );
        setSelectedCabnite(updatedCabnite);
        const updatedFloorCabnites = floorCabnites.map((cabnite) =>
          cabnite.id === updatedCabnite.id ? updatedCabnite : cabnite
        );
        if (
          !updatedFloorCabnites.some(
            (cabnite) => cabnite.id === updatedCabnite.id
          )
        ) {
          updatedFloorCabnites.push(updatedCabnite);
        }
        setFloorCabnites(updatedFloorCabnites);
        const response = await buildingService.updateFloorObjects(
          {
            floor_object_map: updatedFloorCabnites,
            floor_polygon_id: currentFloor?.floor_polygon_id,
          },
          token
        );
        let floor = response?.data?.data;
        if (floor) {
          dispatch(
            updateFloorObjectMap({
              floor_object_map: updatedFloorCabnites,
              site_id: currentSite?.id,
              building_id: 1,
              project_id: currentSite?.project_id,
              floor_polygon_id: currentFloor?.floor_polygon_id,
            })
          );
          setCurrentFloor(floor);
        }
        // console.log("Master Update:",JSON.stringify(updatedFloorCabnites));
      }
    };
    updateFloorObjects();
  }, [floorRackSocketItems]);

  /**
   * Use to save the floor  Racks
   */
  useEffect(() => {
    const updateFloorRackObjects = async () => {
      if (
        floorRacks &&
        floorRacks.length > 0 &&
        currentFloor?.floor_polygon_id
      ) {
        const floorRackWithoutImage = floorRacks.map(({ image, ...rest }) => ({
          ...rest,
        }));
        const currentCabnite = floorCabnites.find(
          (cab) => cab.id === selectedCabnite?.id
        );
        if (!currentCabnite) {
          return;
        }
        const updatedCabnite = updateSelectedRack(
          currentCabnite,
          floorRackWithoutImage
        );
        setSelectedCabnite(updatedCabnite);
        const updatedFloorCabnites = floorCabnites.map((cabnite) =>
          cabnite.id === updatedCabnite.id ? updatedCabnite : cabnite
        );
        // Add the updatedCabnite if it doesn't exist in the array
        if (
          !updatedFloorCabnites.some(
            (cabnite) => cabnite.id === updatedCabnite.id
          )
        ) {
          updatedFloorCabnites.push(updatedCabnite);
        }
        setFloorCabnites(updatedFloorCabnites);
        const response = await buildingService.updateFloorObjects(
          {
            floor_object_map: updatedFloorCabnites,
            floor_polygon_id: currentFloor?.floor_polygon_id,
          },
          token
        );
        let floor = response?.data?.data;
        if (floor) {
          dispatch(
            updateFloorObjectMap({
              floor_object_map: updatedFloorCabnites,
              site_id: currentSite?.id,
              building_id: 1,
              project_id: currentSite?.project_id,
              floor_polygon_id: currentFloor?.floor_polygon_id,
            })
          );
          setCurrentFloor(floor);
        }
      }
    };
    updateFloorRackObjects();
  }, [floorRacks]);
  /**
   * Save floor Cabnits
   */
  useEffect(() => {
    const updateFloorCabnites = async () => {
      if (
        floorCabnites &&
        floorCabnites.length > 0 &&
        currentFloor?.floor_polygon_id
      ) {
        const floorCabnitesWithoutImage = floorCabnites?.map(
          ({ image, ...rest }) => ({
            ...rest,
          })
        );

        const response = await buildingService.updateFloorObjects(
          {
            floor_object_map: floorCabnitesWithoutImage,
            floor_polygon_id: currentFloor?.floor_polygon_id,
          },
          token
        );
        let cabnites = response?.data?.data;
        /**
         * Data is not Saved at the moment Need to fix this object ----? tommorow
         */
        if (cabnites) {
          dispatch(
            updateFloorObjectMap({
              floor_object_map: floorCabnites,
              site_id: currentSite?.id,
              building_id: 1,
              project_id: currentSite?.project_id,
              floor_polygon_id: currentFloor?.floor_polygon_id,
            })
          );
          setCurrentFloor(cabnites);
        }
      }
    };
    updateFloorCabnites();
  }, [floorCabnites]);
  useEffect(() => {
    if (
      currentFloor &&
      currentFloor.floor_object_map &&
      currentFloor.floor_object_map.length > 0 &&
      JSON.stringify(currentFloor.floor_object_map) !==
        JSON.stringify(floorCabnites)
    ) {
      setFloorCabnites(currentFloor.floor_object_map);
    }
  }, [currentFloor]);

  const handelClearFloorSelection = (selectedCabnite) => {
    //Close the Rack details model
    if (selectedCabnite) {
      setFloorRackSocketItems([])
      setFloorRacks([]);
      setSelectedCabnite(null);
      return;
    }
    setActiveFloorIndex([-1, -1, -1, -1]);
    setCurrentFloor({});
    setFloorRacks([]);
    setFloorCabnites([]);
    setDirectSelectedFloorId(null);
    setIsFloorSelected(false);
  };
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const handelCreateNewProject = () => {
    setShowProjectForm(true);
  };
  const handelCloseFormModal = (reqestedMode) => {
    setShowProjectForm(false);
    setIsSetupModalVisible(false);
    setEditProject({});
    setIsProjectFormEdit(false);
    setEditSite({});
    setIsSiteFormEdit(false);
  };

  const generateHeaderLabelingObject = (type) => {
    switch (type) {
      case "INITIAL_POJECT_SELECTION":
        return {
          title: "Cabling Installer Projects",
          subTitle: "Choose a Project",
        };
      case "Certification":
        return {
          title: "Certification is not available yet.",
          subTitle: "Coming Soon",
        };
      case "Labeling":
        return {
          title: "Labeling is not available yet.",
          subTitle: "Coming Soon",
        };
      default:
        return {
          title: "Cabling Installer Specialist",
          subTitle: "Choose a Category",
        };
    }
  };
  /**
   * MAP FUNCTIONS
   */
  const handleToggleToProjectListingMode = () => {
    dispatch(setActiveMarker({ marker: null }));
    dispatch(setCanOpenLeftSidePanel({ canOpenLeftSidePanel: MODE.NO }));
    dispatch(
      setCurrentMode({
        mode: MODE.INITIAL_POJECT_SELECTION,
        viewer: MODE.ACTIVATE_INITIAL_OVERLAY,
        props: { previewImage: { show: false, imageObject: {} } },
      })
    );
    dispatch(setClearActiveProject());

    setActiveFloorIndex([-1, -1, -1, -1]);
    setActiveBuildingIndex([-1, -1, -1]);
    setActiveSiteIndex([-1, -1]);
    setCurrentBuilding({});
    setCurrentSite({});
    setStructureMapMarkers([]);
    setPolygons([]);
  };
  return (
    <SetupContext.Provider
      value={{
        structureMapMarkers,
        setStructureMapMarkers,
        categories,
        setCategories,
        isSetupModalVisible,
        setIsSetupModalVisible,
        isShowProjectForm,
        currentProject,
        setCurrentProject,
        setShowProjectForm,
        currentBuilding,
        setCurrentBuilding,
        currentSite,
        setCurrentSite,
        currentFloor,
        setCurrentFloor,
        handelCreateNewProject,
        handelCloseFormModal,
        generateHeaderLabelingObject,
        polygonLatLongTracker,
        setPolygonLatLongTracker,
        isEmptyObject,
        selectedBuildingMarker,
        setSelectedBuildingMarker,
        isClickOutsideDisabled,
        setIsClickOutsideDisabled,
        buildingMarkerContextMenu,
        setBuildingMarkerContextMenu,
        undoData,
        setUndoData,
        polygons,
        setPolygons,
        originalMarkerPositions,
        setOriginalMarkerPositions,
        polygonCardData,
        setPolygonCardData,
        currentMapTool,
        setCurrentMapTool,
        zoomLevelMap,
        setZoomLevelMap,
        mapRef,
        activeFloorIndex,
        setActiveFloorIndex,
        activeBuildingIndex,
        setActiveBuildingIndex,
        activeSiteIndex,
        setActiveSiteIndex,
        showSites,
        setShowSites,
        handelClearFloorSelection,
        directSelectedFloorId,
        setDirectSelectedFloorId,
        structures,
        googleMapsApiKey,
        isProjectFormEdit,
        setIsProjectFormEdit,
        editProject,
        setEditProject,
        isSiteFormEdit,
        setIsSiteFormEdit,
        editSite,
        setEditSite,
        handleToggleToProjectListingMode,
        racks,
        setRacks,
        cabnites,
        setCabnites,
        floorRacks,
        setFloorRacks,
        isFloorSelected,
        setIsFloorSelected,
        selectedCabnite,
        setSelectedCabnite,
        rackEquipmentSockets,
        setRackEquipmentSockets,
        floorCabnites,
        setFloorCabnites,
        floorRackSocketItems,
        setFloorRackSocketItems,
        isRackSelected,
        setIsRackSelected
      }}
    >
      {children}
    </SetupContext.Provider>
  );
};

export const useSetupContext = () => useContext(SetupContext);
