export const Roles = { INSTALLER: "installer", END_USER: "end_user" };
export const MODE = {
  INITIAL_STAGE: "INITIAL_STAGE",
  INITIAL_POJECT_SELECTION: "INITIAL_POJECT_SELECTION",
  NEW_PROJECT_SETUP_END_INITIAL: "NEW_PROJECT_SETUP_END_INITIAL",
  NEW_PROJECT_SETUP_INTERNAL: "NEW_PROJECT_SETUP_INTERNAL",
  OPEN_PANEL_VIEW: "OPEN_PANEL_VIEW",
  YES: true,
  NO: false,
  FREE: "FREE",
  NEW_BUILDING_SETUP_MODE: "NEW_BUILDING_SETUP_MODE",
  NEW_FLOOR_SETUP_MODE: "NEW_FLOOR_SETUP_MODE",
  ACTIVATE_VIEWER: "ACTIVATE_VIEWER",
  ACTIVATE_INITIAL_OVERLAY: "ACTIVATE_INITIAL_OVERLAY",
  ACTIVATE_GOOGLE_MAP_VIEW: "ACTIVATE_GOOGLE_MAP_VIEW",
  ACTIVATE_GOOGLE_MAP: "ACTIVATE_GOOGLE_MAP",
};

export const initMode = {
  mode: MODE.INITIAL_STAGE,
  viewer: MODE.ACTIVATE_INITIAL_OVERLAY,
  props: {
    prevMode: MODE.INITIAL_STAGE,
    previewImage: { show: false, imageObject: {} },
  },
};

/**
 * MAP SETTINGS
 */

export const POLYGON_CARD_SIZE = 300;
export const NATIVE_TRIANGLE_SIZE = 12;
export const POLYGON_CARD_RIGHT_OFFSET = 20;

export const POLYGON_NODES_MIN_QUANTITY = 3;
export const POLYLINE_NODES_MIN_QUANTITY = 2;

export const NamesToColors = {

  Blue: "#4d00f9",
  LightBlue: "#ADD8E6",
  Pink: "#FF69B4", 
  Red: "#FF4500",
  Green: "#32CD32",
};
export const BorderWidth = {
  ExtraSmall: 1,
  Small: 2,
  Medium: 3,
  Large: 4,
  ExtraLarge: 5
};

export const PlusSymbol = {
  path: "M0,-1 V1 M-1,0 H1",
  strokeOpacity: 1,
  scale: 3,
};

export const MapConfigOptions = {
  zoomControl: false,
  mapTypeControl: false,
  disableDefaultUI: true, 
  fullscreenControl: false,
  streetViewControl: false,
  gestureHandling: "auto", 
};

export const MapConfig = {
  mapContainerStyle: {
    width: "100%",
    height: "100%",
  },
  options: MapConfigOptions
};

export const PolygonOptions = {
  strokeWeight:1,
  fillOpacity: 0.3,
  fillColor: NamesToColors.Blue,
  strokeColor:NamesToColors.Pink,
  suppressUndo: true,
};

export const PolylineOptions = {
  strokeWeight:1,
  fillOpacity: 0.3,
  strokeColor: "#FFF",
  suppressUndo: true,
  icons: [
    {
      icon: PlusSymbol,
      repeat: "22px",
    },
  ],
};

export const MapSettings = {
  MapConfig,
  PolygonOptions,
  PolylineOptions,
  CircleOptions: PolygonOptions,
  InfoWindowOptions: {
    Triangle: {
      width: 13,
      height: 8,
    },
    PixelOffset: { X: 0, Y: -5 },
    Polygon: {
      UndoPixelOffset: { X: 0, Y: -5 },
      CardPixelOffset: {
        X: POLYGON_CARD_SIZE / 2 + POLYGON_CARD_RIGHT_OFFSET,
        Y: (POLYGON_CARD_SIZE + NATIVE_TRIANGLE_SIZE) / 2,
      },
    },
  },
};
export const getDynamicPolygonOptions = (options) => {
  return {
    strokeWeight: options?.borderWidth || 1,
    fillOpacity: 0.3,
    fillColor: options?.backgroundColor || NamesToColors.Red,
    strokeColor: options?.borderColor || NamesToColors.Red,
    suppressUndo: true,
  };
};

export const _objectType = {
  RACK_ITEM: "rack_item",
  RACK :"rack"
};
export const _droppableId ={
  MAP:"map",
  RACK_ITEMS:"rack-items",
  RACKS:"racks",
  RACK_ITEM_STAGE:"rack-item-stage",
  FLOOR_ITEM_STAGE:"floor-item-stage",
  CABNITES: "cabnites"
}
