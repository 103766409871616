import React, { useCallback, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useViewer } from "../../context/ViewerContext";
import {
  _droppableId,
  _objectType,
  handleImageUrlToBase64,
  isEmptyObject,
  isPointInPolygon,
} from "../../_helpers";
import { nanoid } from "nanoid";
import { buildingService } from "../../_services";
import { useSelector } from "react-redux";
import { getToken } from "../../store/slices/auth";
import { setBuilding } from "../../store/slices/setup";
import { useDispatch } from "react-redux";
import { useFloorViewer } from "../../context/FloorViewerContext";
import { useSetupContext } from "../../context/SetupContext";
import toast from "react-hot-toast";
const DragNDropHolder = ({ children }) => {
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const {
    racks,
    rackEquipmentSockets,
    currentSite,
    setStructureMapMarkers,
    setFloorRacks,
    polygonLatLongTracker,
    setPolygonLatLongTracker,
    currentFloor,
    structures,
    setFloorCabnites,
    cabnites,
    floorRacks,
    setFloorRackSocketItems,
  } = useSetupContext();
  const { handelLoadRegularImageObjectURL, handelLoadRegularRackItemURL } =
    useViewer();
  const { floorStageRef } = useFloorViewer();

  const onDragEnd = async (result) => {
    if (isEmptyObject(currentSite)) {
      alert("There is no active site available to place the structure.");
      return;
    }

    const { destination, source } = result;

    /**
     * ----------------------
     * Cabnites Boxs On Floor
     * ----------------------
     */
    if (
      destination.droppableId === "floor-item-stage" &&
      source.droppableId === "cabnites"
    ) {
      const droppedRack = cabnites[source.index];
      const { image_full_url, name } = droppedRack;
      const rack = await handlePlacement(droppedRack, "cabnites");
      rack.x = floorStageRef.current.x;
      rack.y = floorStageRef.current.y;
      rack.name = name;
      rack.floor_polygon_id = currentFloor?.floor_polygon_id;
      rack.image_full_url = image_full_url;
      setFloorCabnites((prevRack) => [...prevRack, rack]);
      return;
    }
    if (
      destination.droppableId !== _droppableId?.FLOOR_ITEM_STAGE &&
      source.droppableId === _droppableId?.CABNITES
    ) {
      toast.error("Cabnites can only be placed on floor.");
      return;
    }
    if (
      destination.droppableId !== _droppableId?.RACK_ITEM_STAGE &&
      (source.droppableId === _droppableId?.RACK_ITEMS ||
        source.droppableId === _droppableId?.RACKS)
    ) {
      toast.error("Items are not allowed direclty on the floor.");
      return;
    }
    /**
     * -------------------
     * Rack
     * -------------------
     */
    if (destination.droppableId === _droppableId?.RACK_ITEM_STAGE) {
      /**
       * --------------------
       * Rack Items
       * --------------------
       */
      if (source?.droppableId === _droppableId?.RACK_ITEMS) {
        if (floorRacks.length <= 0 && currentFloor?.floor_polygon_id) {
          toast.error("Please place a rack first.");
          return;
        }
        const droppedRackEquipment = rackEquipmentSockets[source.index];
        const { image_full_url, name } = droppedRackEquipment;
        const rackEquipment = await handleRackItemPlacement(
          droppedRackEquipment
        );
        rackEquipment.x = floorStageRef.current.x;
        rackEquipment.y = floorStageRef.current.y;
        rackEquipment.name = name;
        rackEquipment.floor_polygon_id = currentFloor?.floor_polygon_id;
        rackEquipment.image_full_url = image_full_url;

        setFloorRackSocketItems((prevRackEquipment) => [
          ...prevRackEquipment,
          rackEquipment,
        ]);
      }
      /**
       * --------------------
       * Rack
       * --------------------
       */
      if (source?.droppableId === _droppableId?.RACKS) {
        if (floorRacks.length >= 1 && currentFloor?.floor_polygon_id) {
          toast.error("Each cabinet can only contain one rack.");
          return;
        }
        const droppedRack = racks[source.index];
        const { image_full_url, name } = droppedRack;
        const rack = await handlePlacement(droppedRack, _objectType?.RACK);
        rack.x = floorStageRef.current.x;
        rack.y = floorStageRef.current.y;
        rack.width = 312;
        rack.height = 900;
        rack.name = name;
        rack.floor_polygon_id = currentFloor?.floor_polygon_id;
        rack.image_full_url = image_full_url;
        setFloorRacks((prevRack) => [...prevRack, rack]);
      }
      return;
    }

    try {
      if (!destination || destination.droppableId !== _droppableId?.MAP) return;
      if (!currentSite?.polygon_marker) return;
      const building = await handleStructurePlacement(
        structures[source.index],
        currentSite,
        polygonLatLongTracker,
        token
      );

      if (building) {
        setStructureMapMarkers((prevMarkers) => [...prevMarkers, building]);
        dispatch(setBuilding({ building: building, site: currentSite }));
      }
    } catch (error) {
      console.log(error);
      // alert(error.message);
      setPolygonLatLongTracker({});
    }
  };
  const handlePlacement = async (imageObject, type) => {
    return new Promise((resolve, reject) => {
      handleImageUrlToBase64(imageObject.image_full_url, (base64) => {
        if (base64) {
          handelLoadRegularImageObjectURL(base64, type)
            .then((rack) => {
              resolve(rack);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          console.error("Failed to convert image URL to Base64");
          reject("Failed to convert image URL to Base64");
        }
      });
    });
  };

  const handleRackItemPlacement = async (rackObject) => {
    return new Promise((resolve, reject) => {
      handleImageUrlToBase64(rackObject.image_full_url, (base64) => {
        if (base64) {
          handelLoadRegularRackItemURL(base64)
            .then((rack) => {
              resolve(rack);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          console.error("Failed to convert image URL to Base64");
          reject("Failed to convert image URL to Base64");
        }
      });
    });
  };
  const handleStructurePlacement = async (
    droppedMarker,
    currentSite,
    polygonLatLongTracker,
    token
  ) => {
    if (
      isPointInPolygon(
        polygonLatLongTracker,
        currentSite?.polygon_marker?.coordinates
      )
    ) {
      const marker = {
        id: nanoid(),
        position: polygonLatLongTracker,
        svg: droppedMarker.image_full_url,
      };
      const buildingObject = {
        name: "Demo Building",
        description: "Building details needed",
        image: null,
        site_id: parseInt(currentSite?.id),
        longitude: marker.position.lng,
        latitude: marker.position.lat,
        floor_qty: 0,
        marker: JSON.stringify(marker),
      };

      try {
        const response = await buildingService.createBuilding(
          buildingObject,
          token
        );
        buildingObject.marker = JSON.parse(buildingObject.marker);
        if (response.data.code === 201) {
          return response.data.data;
        }
      } catch (error) {
        console.error("Error creating building:", error);
        throw new Error("Failed to create building.");
      }
    } else {
      throw new Error(
        "The selected site is not within the polygon. Please select a site within the polygon."
      );
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
    </>
  );
};

export default DragNDropHolder;
