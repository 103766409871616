import { nanoid } from "nanoid";
import { createContext, useContext, useRef, useState, useEffect } from "react";
import { useViewer } from "./ViewerContext";
const MarkingStageContext = createContext();
export const MarkingStageProvider = ({ children }) => {
  const {setCurrentTool} = useViewer()
  const markingStageViewportRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  /**
   *
   * Data states
   */
  const [selectedMarkingObject, setSelectedMarkingObject] = useState(null);
  const [selectedMarkingObjectType, setSelectedMarkingObjectType] =
    useState(null);
  const [isAnyMarkingItemSeleted, setIsAnyMarkingItemSeleted] = useState(false);
  const [markerModelHeading, setMarkerModelHeading] = useState(null);
  const [activeMarkingLayerId, setActiveMarkingLayerId] = useState(null);
  const [markingLayers, setMarkingLayers] = useState([]);
  const [selectedMarkerShapes, setSelectedMarkerShapes] = useState([]);
  const [selectedMarkerTool, setSelectedMarkerTool] = useState(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [isDrawing, setIsDrawing] = useState(false);
    const [copiedShapes, setCopiedShapes] = useState(null);
  // Zooming from the center
  const handleWheel = (e) => {
    if (contextMenuVisible) {
      setContextMenuVisible(false);
    }
    e.evt.preventDefault();
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    if (!pointerPosition) return;
    const oldScale = scale;
    const scaleBy = e.evt.deltaY < 0 ? 1.05 : 0.95;
    let newScale = oldScale * scaleBy;
    const minScale = 0.1;
    const maxScale = 15;
    if (newScale < minScale) newScale = minScale;
    if (newScale > maxScale) newScale = maxScale;
    const stagePosition = stage.position();
    const pointerOffsetX = pointerPosition.x - stagePosition.x;
    const pointerOffsetY = pointerPosition.y - stagePosition.y;

    setScale(newScale);
    const newPosition = {
      x: pointerPosition.x - pointerOffsetX * (newScale / oldScale),
      y: pointerPosition.y - pointerOffsetY * (newScale / oldScale),
    };

    setPosition(newPosition);
  };

  /**
   * state watcher
   */
  useEffect(() => {
    if (selectedMarkingObject !== null) {
      setIsAnyMarkingItemSeleted(true);
      const defaultLayerId = nanoid();
      setActiveMarkingLayerId(defaultLayerId);
      setMarkingLayers([
        {
          id: defaultLayerId,
          shapes: [],
        },
      ]);
    } else {
      handelResetMarkingSelection();
    }
  }, [selectedMarkingObject]);

  const handelResetMarkingSelection = () => {
    setSelectedMarkerShapes([]);
    setCurrentTool("")
    setSelectedMarkingObject(null);
    setIsAnyMarkingItemSeleted(false);
    setMarkerModelHeading(null);
    setSelectedMarkingObjectType(null);
    setPosition({ x: 0, y: 0 });
    setScale(1);
    setIsDrawing(false)
    setCopiedShapes(null)
  };
  const handelAddShapeToMarkingLayer = (layerId, shape) => {
    setMarkingLayers(
      markingLayers.map((layer) =>
        layer.id === layerId
          ?{ ...layer, shapes: [...layer.shapes, ...(Array.isArray(shape) ? shape : [shape])] }
          : layer
      )
    );
  };
  const handelUpdateMarkerShape = (updatedShape) => {
    if (selectedMarkerShapes) {
      const updatedLayers = [...markingLayers];
      const selectedLayerIndex = updatedLayers.findIndex(
        (layer) => layer.id === activeMarkingLayerId
      );
      if (selectedLayerIndex !== -1) {
        const selectedLayer = updatedLayers[selectedLayerIndex];
        const shapeIndex = selectedLayer.shapes.findIndex(
          (shape) => shape.id === updatedShape.id
        );
        if (shapeIndex !== -1) {
          selectedLayer.shapes[shapeIndex] = updatedShape;
          setMarkingLayers(updatedLayers);
        }
      }
    }
  };
  const handleAddMarkingRectShape = (layerId, x, y) => {
    const newRect = {
      id: nanoid(),
      type: "rect",
      refType: "marking-rectangle",
      x,
      y,
      width: 20,
      height: 20,
      fill: "#2bd94e47",
      isLocked: false,
      stroke: "#000000",   
      strokeWidth:0.5,
    };
    handelAddShapeToMarkingLayer(layerId, newRect);
    setSelectedMarkerTool(null)
    setCurrentTool("")
  };
  const handleContextMenu = (e, shape) => {
    e.evt.preventDefault();
    e.evt.stopPropagation();
    setSelectedMarkerTool(null);
    const mousePosition = e.target.getStage().getPointerPosition();
    setContextMenuPosition({ x: mousePosition.x, y: mousePosition.y });
    setContextMenuVisible(true);
    // Set the select ed shape in the context menu
    setSelectedMarkerShapes([shape]);
  };
  const handelCreateDuplicateMarkingObject = () => {
    if (selectedMarkerShapes) {
      setMarkingLayers((prevLayers) => {
        const updatedLayers = [...prevLayers];
        const selectedLayerIndex = updatedLayers.findIndex(
          (layer) => layer.id === activeMarkingLayerId
        );
        if (selectedLayerIndex !== -1) {
          const selectedLayer = updatedLayers[selectedLayerIndex];
          const duplicateShape = { ...selectedMarkerShapes, id: nanoid() };
          selectedLayer.shapes.push(duplicateShape);
        }
        return updatedLayers;
      });
    }
  };
  const handelDeleteMarkingObject = () => {
    if (selectedMarkerShapes && selectedMarkerShapes.length > 0) {
      setMarkingLayers((prevLayers) => {
        const updatedLayers = [...prevLayers];
        const selectedLayerIndex = updatedLayers.findIndex(
          (layer) => layer.id === activeMarkingLayerId
        );
        if (selectedLayerIndex !== -1) {
          const selectedLayer = updatedLayers[selectedLayerIndex];
  
          // Filter out shapes that are not in selectedMarkerShapes
          selectedLayer.shapes = selectedLayer.shapes.filter(
            (shape) => !selectedMarkerShapes.some((selected) => selected.id === shape.id)
          );
        }
  
        return updatedLayers;
      });
  
      // Clear the selectedMarkerShapes after deletion
      setSelectedMarkerShapes([]);
    }
  };
  
  // const handelDeleteMarkingObject = () => {
  //   if (selectedMarkerShapes) {
  //     setMarkingLayers((prevLayers) => {
  //       const updatedLayers = [...prevLayers];
  //       const selectedLayerIndex = updatedLayers.findIndex(
  //         (layer) => layer.id === activeMarkingLayerId
  //       );
  //       if (selectedLayerIndex !== -1) {
  //         const selectedLayer = updatedLayers[selectedLayerIndex];
  //         const shapeIndex = selectedLayer.shapes.findIndex(
  //           (shape) => shape.id === selectedMarkerShapes.id
  //         );
  //         if (shapeIndex !== -1) {
  //           selectedLayer.shapes.splice(shapeIndex, 1);
  //         }
  //       }
  //       return updatedLayers;
  //     });
  //   }
  // };

  return (
    <MarkingStageContext.Provider
      value={{
        scale,
        position,
        handleWheel,
        setScale,
        selectedMarkingObject,
        setSelectedMarkingObject,
        isAnyMarkingItemSeleted,
        setIsAnyMarkingItemSeleted,
        markingStageViewportRef,
        handelResetMarkingSelection,
        markerModelHeading,
        setMarkerModelHeading,
        selectedMarkingObjectType,
        setSelectedMarkingObjectType,
        handelAddShapeToMarkingLayer,
        markingLayers,
        handelUpdateMarkerShape,
        selectedMarkerShapes,
        setSelectedMarkerShapes,
        activeMarkingLayerId,
        selectedMarkerTool,
        setSelectedMarkerTool,
        handleAddMarkingRectShape,
        contextMenuPosition,
        setContextMenuPosition,
        contextMenuVisible,
        setContextMenuVisible,
        handleContextMenu,
        handelCreateDuplicateMarkingObject,
        handelDeleteMarkingObject,
        copiedShapes, setCopiedShapes,
    isDrawing, setIsDrawing
      }}
    >
      <div
        ref={markingStageViewportRef}
        className="viewer"
        id="marking-stage-scean-viewport"
      >
        {children}
      </div>
    </MarkingStageContext.Provider>
  );
};

export const useMarkingStateView = () => useContext(MarkingStageContext);
