/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { FloorViewerProvider } from "../../context/FloorViewerContext";
import SiteLayoutHolder from "./SiteLayoutHolder";
import { ViewerProvider } from "../../context/ViewerContext";
import { SetupProvider } from "../../context/SetupContext";
import { MarkingStageProvider } from "../../context/MarkingStateContext";
const SiteLayout = ({ children }) => {
  return (
    <SetupProvider>
      <ViewerProvider>
        <FloorViewerProvider>
          <MarkingStageProvider>
            <SiteLayoutHolder>{children}</SiteLayoutHolder>
          </MarkingStageProvider>
        </FloorViewerProvider>
      </ViewerProvider>
    </SetupProvider>
  );
};

export default SiteLayout;
