import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useSetupContext } from "../../context/SetupContext";
import { useMarkingStateView } from "../../context/MarkingStateContext";
import { _objectType } from "../../_helpers";

const RackItemsList = () => {
  const { rackEquipmentSockets } = useSetupContext();
  const { setSelectedMarkingObject,setMarkerModelHeading,setSelectedMarkingObjectType } = useMarkingStateView();
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index, rackEquipmentSocket) => {
    setMarkerModelHeading("Rack Management")
    setActiveIndex(index);
    setSelectedMarkingObject(rackEquipmentSocket);
    setSelectedMarkingObjectType(_objectType.RACK_ITEM);
  };
  return (
    <Droppable droppableId="rack-items">
      {(provided) => (
        <div>
          <div
            className="gird--item--section-full"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {rackEquipmentSockets.map((rackEquipmentSocket, index) => (
              <Draggable key={index} draggableId={String(index)} index={index}>
                {(provided) => (
                  <div
                    className={`gird--item ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() => handleItemClick(index, rackEquipmentSocket)}
                  >
                    <div className="gird--item-content gird--item-content-full mt-1">
                      <img
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        src={rackEquipmentSocket.image_full_url}
                        alt={`Rack-item-${index}`}
                      />
                      <h6>{rackEquipmentSocket.name}</h6>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default RackItemsList;
