/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Ruler from "@scena/ruler";
import Konva from "konva";
import { nanoid } from "nanoid";
import {
  getActiveMarker,
  getActiveProject,
  getCurrentMode,
  setActiveMarker,
  setCurrentMode,
  updateBuilding,
} from "../store/slices/setup";
import { useSelector } from "react-redux";
import { buildingService } from "../_services";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getToken } from "../store/slices/auth";
import { _objectType, MODE } from "../_helpers";
import { object } from "yup";
const ViewerContext = createContext();
export const ViewerProvider = ({ children }) => {
  const dispatch = useDispatch();
  const project = useSelector(getActiveProject);
  const setupMode = useSelector(getCurrentMode);
  const token = useSelector(getToken);
  const activeMarker = useSelector(getActiveMarker);
  const viewportRef = useRef(null);
  const hRulerRef = useRef(null);
  const vRulerRef = useRef(null);
  const buffer = useRef([]);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [horizontalRuler, setHorizontalRuler] = useState(null);
  const [verticalRuler, setVerticalRuler] = useState(null);
  const [isViewerSetupDone, setIsViewerSetupDone] = useState(false);
  const [stageBackgroundColor, setStageBackgroundColor] = useState("#CCCCCC");
  const [layers, setLayers] = useState([]);
  const [selectedShape, setSelectedShape] = React.useState(null);
  const [activeLayerId, setActiveLayerId] = useState(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  //Polygon
  const [isDrawPoly, setIsDrawPoly] = useState(false);
  const [isDrawLine, setIsDrawLine] = useState(false);
  const [polyPoints, updatePolyPoints] = useState(1);
  const [polyIsOverStart, setPolyIsOverStart] = useState(false);
  const [nextAnnotation, setNextAnnotation] = useState([]);
  const [nextLineAnnotation, setNextLineAnnotation] = useState([]);
  const [currentTool, setCurrentTool] = useState("");
  const [idForPolygon, setIdForPolygon] = useState(nanoid());
  const [idForLine, setIdForLine] = useState(nanoid());
  const [floorImageObj, setFloorImageObj] = useState(null);
  const [isUpdateFloorModelVisible, setIsUpdateFloorModelVisible] =
    useState(false);
  const [floorData, setfloorData] = useState({});
  const [shapesTooltip, setShapesTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    text: "",
  });
  const getStageMousePos = useCallback((event) => {
    const stage = event.target?.getStage();
    if (!stage) return [0, 0];

    const pointerPosition = stage.getPointerPosition();
    if (!pointerPosition) return [0, 0]; // handle null pointerPosition

    const { x, y } = pointerPosition;

    // Get the current scale and position of the stage
    const currentScale = stage.scaleX();
    const stagePosition = stage.position();

    // Calculate nextX and nextY considering scale and stage position
    let nextX = (x - stagePosition.x) / currentScale;
    let nextY = (y - stagePosition.y) / currentScale;

    return [nextX, nextY];
  }, []);
  const handelUpdateFloorDetails = (shape) => {
    setIsUpdateFloorModelVisible(true);
    setfloorData(shape);
  };
  const saveCanvasState = () => {
    const canvasState = {
      // other state
      layers: layers.map((layer) => ({
        ...layer,
        shapes: layer.shapes.map((shape) => {
          if (shape.type === "image" && shape.image) {
            // Convert image to data URL
            const canvas = document.createElement("canvas");
            canvas.width = shape.image.width;
            canvas.height = shape.image.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(shape.image, 0, 0);
            return {
              ...shape,
              image: canvas.toDataURL(),
            };
          }
          return shape;
        }),
      })),
    };

    const canvasStateJSON = JSON.stringify(canvasState);
    return canvasStateJSON;
  };
  const setupViewer = () => {
    const width = viewportRef?.current?.clientWidth;
    const height = viewportRef?.current?.clientHeight;

    const horizontalRuler = createRulerRef(hRulerRef, "horizontal", width);
    const verticalRuler = createRulerRef(vRulerRef, "vertical", height);
    setHorizontalRuler(horizontalRuler);
    setVerticalRuler(verticalRuler);
    horizontalRuler.scroll(0 - 0.5);
    verticalRuler.scroll(0 - 0.5);
    setIsViewerSetupDone(true);
    // handelAddDefaultLayer();
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (MODE.ACTIVATE_GOOGLE_MAP_VIEW === setupMode?.viewer) {
        return;
      }
      localStorage.setItem("userLeaving", "true");
      const confirmationMessage = "Are you sure you want to leave?";
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (setupMode?.mode === MODE.ACTIVATE_VIEWER) {
      dispatch(
        setCurrentMode({
          mode: MODE.ACTIVATE_GOOGLE_MAP,
          viewer: MODE.ACTIVATE_GOOGLE_MAP_VIEW,
        })
      );
      dispatch(setActiveMarker({ marker: null }));
    }
    const userLeaving = localStorage.getItem("userLeaving");
    if (userLeaving === "true") {
      dispatch(
        setCurrentMode({
          mode: MODE.ACTIVATE_GOOGLE_MAP,
          viewer: MODE.ACTIVATE_GOOGLE_MAP_VIEW,
        })
      );
      dispatch(setActiveMarker({ marker: null }));
      localStorage.removeItem("userLeaving");
    }
  }, [dispatch]);

  const handelAddDefaultLayer = (site_id) => {
    //Setup default layer on inital load
    const defaultLayerId = nanoid();
    setLayers([
      {
        id: defaultLayerId,
        site_id: site_id,
        building_id: null,
        name: null,
        shapes: [],
      },
    ]);
    handelSetActiveLayerId(defaultLayerId);
  };

  const handleContextMenu = (e, shape) => {
    e.evt.preventDefault();
    e.evt.stopPropagation();
    const mousePosition = e.target.getStage().getPointerPosition();
    setContextMenuPosition({ x: mousePosition.x, y: mousePosition.y });
    setContextMenuVisible(true);
    // Set the select ed shape in the context menu
    setSelectedShape(shape);
  };
  const createRuler = (elementId, type, size) => {
    return new Ruler(document.getElementById(elementId), {
      type: type,
      backgroundColor: "#fff",
      lineColor: "#000000",
      textColor: "#000000",
      unit: 1,
      zoom: 100,
      size: size,
    });
  };

  const createRulerRef = (ref, type, size) => {
    return new Ruler(ref.current, {
      type: type,
      backgroundColor: "#fff",
      lineColor: "#000000",
      textColor: "#000000",
      unit: 1,
      zoom: 100,
      size: size,
    });
  };

  const handelResetView = () => {
    horizontalRuler.scroll(0 - 0.5);
    verticalRuler.scroll(0 - 0.5);
    setScale(1);
    setPosition({ x: 0, y: 0 });
    setContextMenuVisible(false);
  };

  const handleStageBackgroundColor = () => {
    setStageBackgroundColor(Konva.Util.getRandomColor());
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();
    setContextMenuVisible(false);
    const stage = e.target.getStage();
    const oldScale = scale;
    const scaleBy = e.evt.deltaY < 0 ? 1.05 : 0.95; // Invert the zoom direction
    const pointerPos = stage.getPointerPosition();
    let newScale = oldScale * scaleBy;
    // Set minimum and maximum scale limits
    const minScale = 0.1;
    const maxScale = 10;
    if (newScale < minScale) newScale = minScale;
    if (newScale > maxScale) newScale = maxScale;
    setScale(newScale);
    const newPos = {
      x: pointerPos.x - (pointerPos.x - position.x) * (newScale / oldScale),
      y: pointerPos.y - (pointerPos.y - position.y) * (newScale / oldScale),
    };
    setPosition(newPos);
    // Adjust rulers based on new position and scale
    horizontalRuler.zoom = newScale * 100;
    verticalRuler.zoom = newScale * 100;

    horizontalRuler.scroll(newPos.x);
    verticalRuler.scroll(newPos.y);
  };
  const handleDragMove = (e) => {
    setContextMenuVisible(false);
    const stage = e.target.getStage();
    const newPosition = stage.position();
    horizontalRuler.scroll(newPosition.x);
    verticalRuler.scroll(newPosition.y);
    setPosition(newPosition);
  };

  const handelAddLayer = () => {
    setLayers([...layers, { id: nanoid(), name: nanoid(), shapes: [] }]);
  };

  const handelAddShapeToLayer = (layerId, shape) => {
    setLayers(
      layers.map((layer) =>
        layer.id === layerId
          ? { ...layer, shapes: [...layer.shapes, shape] }
          : layer
      )
    );
  };

  const handelAddLineToLayer = (layerId, shape) => {
    const isLine = shape?.type === "line";
    //Check if there's already a line shape with the given ID in the layer
    const shapeExists = layers.some(
      (layer) =>
        layer.id === layerId &&
        layer.shapes.some(
          (existingShape) =>
            existingShape.id === shape.id && existingShape.type === "line"
        )
    );
    setLayers((layers) =>
      layers.map((layer) =>
        layer.id === layerId
          ? {
              ...layer,
              shapes: layer.shapes.map((existingShape) =>
                existingShape.id === shape.id && isLine
                  ? { ...existingShape, points: shape.points }
                  : existingShape
              ),
            }
          : layer
      )
    );
    if (!shapeExists || !isLine) {
      setLayers((layers) =>
        layers.map((layer) =>
          layer.id === layerId
            ? { ...layer, shapes: [...layer.shapes, shape] }
            : layer
        )
      );
    }
  };
  const handelAddPolygonToLayer = (layerId, shape) => {
    const isPolygon = shape?.type === "polygon";
    //Check if there's already a polygon shape with the given ID in the layer
    const shapeExists = layers.some(
      (layer) =>
        layer.id === layerId &&
        layer.shapes.some(
          (existingShape) =>
            existingShape.id === shape.id && existingShape.type === "polygon"
        )
    );
    setLayers((layers) =>
      layers.map((layer) =>
        layer.id === layerId
          ? {
              ...layer,
              shapes: layer.shapes.map((existingShape) =>
                existingShape.id === shape.id && isPolygon
                  ? { ...existingShape, points: shape.points }
                  : existingShape
              ),
            }
          : layer
      )
    );
    if (!shapeExists || !isPolygon) {
      setLayers((layers) =>
        layers.map((layer) =>
          layer.id === layerId
            ? { ...layer, shapes: [...layer.shapes, shape] }
            : layer
        )
      );
    }
  };
  const handelSetActiveLayerId = (layerId) => {
    setActiveLayerId(layerId);
  };

  const handelDeselectObject = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedShape(null);
      setContextMenuVisible(false);
    }
  };

  const handelLoadRegularImageURL = (dataUrl) => {
    setContextMenuVisible(false);
    let image = new window.Image();
    image.src = dataUrl;
    image.addEventListener("load", () => {
      handelAddFloorPlan(image);
    });
    image.addEventListener("error", () => {
      console.error("Failed to load image.");
    });
  };

  const handelLoadRegularImage = (base64) => {
    setContextMenuVisible(false);
    let image = new window.Image();

    image.src = `data:image/png;base64,${base64}`;
    image.addEventListener("load", () => {
      handelAddNewRegularImage(image);
    });
  };
  const handelLoadSVGImage = (svgData) => {
    setContextMenuVisible(false);
    const image = new window.Image();
    image.onload = () => {
      handelAddNewSVGImage(image);
    };
    image.src = "data:image/svg+xml," + encodeURIComponent(svgData);
  };

  const handelAddNewRegularImage = (image) => {
    let newImageObj = {
      x: 100,
      y: 100,
      image: null,
      id: nanoid(),
      type: "image",
      isLocked: false,
      site_id: activeMarker?.site_id,
      building_id: activeMarker?.id,
    };

    newImageObj.image = image;
    handelAddShapeToLayer(activeLayerId, newImageObj);
  };
  const handelAddFloorPlan = (image) => {
    let newImageObj = {
      x: 100,
      y: 100,
      image: null,
      id: nanoid(),
      type: "image",
      isLocked: false,
      site_id: activeMarker?.site_id,
      building_id: activeMarker?.id,
      image_type: "floor_plan",
    };

    newImageObj.image = image;
    setFloorImageObj(newImageObj);
  };
  const handelAddNewSVGImage = (image) => {
    let newImageObj = {
      x: 100,
      y: 100,
      image: null,
      id: nanoid(),
      type: "image",
      isLocked: false,
      site_id: activeMarker?.site_id,
      building_id: activeMarker?.id,
    };
    newImageObj.image = image;
    handelAddShapeToLayer(activeLayerId, newImageObj);
  };
  const handelObjectBackToFrontViceVersa = (front) => {
    if (selectedShape) {
      const updatedLayers = [...layers];
      const selectedLayerIndex = updatedLayers.findIndex(
        (layer) => layer.id === activeLayerId
      );
      if (selectedLayerIndex !== -1) {
        const selectedLayer = updatedLayers[selectedLayerIndex];
        const shapeIndex = selectedLayer.shapes.findIndex(
          (shape) => shape.id === selectedShape.id
        );
        if (shapeIndex !== -1) {
          const shape = selectedLayer.shapes[shapeIndex];
          if (front) {
            selectedLayer.shapes.splice(shapeIndex, 1);
            selectedLayer.shapes.push(shape);
          } else {
            selectedLayer.shapes.splice(shapeIndex, 1);
            selectedLayer.shapes.unshift(shape);
          }
          setLayers(updatedLayers);
        }
      }
    }
  };
  const updateShapeLockStatus = (shapeId, isLocked) => {
    if (selectedShape) {
      const updatedLayers = [...layers];
      const selectedLayerIndex = updatedLayers.findIndex(
        (layer) => layer.id === activeLayerId
      );

      if (selectedLayerIndex !== -1) {
        const selectedLayer = updatedLayers[selectedLayerIndex];
        const shapeIndex = selectedLayer.shapes.findIndex(
          (shape) => shape.id === shapeId
        );

        if (shapeIndex !== -1) {
          const shape = selectedLayer.shapes[shapeIndex];

          const updatedShape = { ...shape, isLocked };
          selectedLayer.shapes[shapeIndex] = updatedShape;

          setLayers(updatedLayers);
        }
      }
    }
  };
  //outer update function
  const handelUpdateShape = (updatedShape) => {
    if (selectedShape) {
      const updatedLayers = [...layers];
      const selectedLayerIndex = updatedLayers.findIndex(
        (layer) => layer.id === activeLayerId
      );
      if (selectedLayerIndex !== -1) {
        const selectedLayer = updatedLayers[selectedLayerIndex];
        const shapeIndex = selectedLayer.shapes.findIndex(
          (shape) => shape.id === updatedShape.id
        );
        if (shapeIndex !== -1) {
          selectedLayer.shapes[shapeIndex] = updatedShape;
          setLayers(updatedLayers);
        }
      }
    }
  };

  const handelDeleteObject = () => {
    if (selectedShape) {
      setLayers((prevLayers) => {
        const updatedLayers = [...prevLayers];
        const selectedLayerIndex = updatedLayers.findIndex(
          (layer) => layer.id === activeLayerId
        );
        if (selectedLayerIndex !== -1) {
          const selectedLayer = updatedLayers[selectedLayerIndex];
          const shapeIndex = selectedLayer.shapes.findIndex(
            (shape) => shape.id === selectedShape.id
          );
          if (shapeIndex !== -1) {
            selectedLayer.shapes.splice(shapeIndex, 1);
          }
        }
        return updatedLayers;
      });
      if (selectedShape.type === "polygon") {
        handelDeleteBuildingFloor(selectedShape?.id);
      }
      console.log(selectedShape, "handel delete shape>>>");
    }
  };

  const handelDeleteBuildingFloor = async (floor_polygon_id) => {
    try {
      const response = await buildingService.deleteBuildingFloor(
        {
          floor_polygon_id: floor_polygon_id,
        },
        token
      );
      let building = response?.data?.data;
      dispatch(
        updateBuilding({
          building: building,
          site: { id: building?.site_id, project_id: project[0]?.id },
        })
      );
      toast.success("Floor deleted successfully");
    } catch (error) {
      console.error("Error saving canvas state:", error);
    }
  };

  const handelCreateDuplicateObject = () => {
    if (selectedShape) {
      setLayers((prevLayers) => {
        const updatedLayers = [...prevLayers];
        const selectedLayerIndex = updatedLayers.findIndex(
          (layer) => layer.id === activeLayerId
        );
        if (selectedLayerIndex !== -1) {
          const selectedLayer = updatedLayers[selectedLayerIndex];
          const duplicateShape = { ...selectedShape, id: nanoid() };
          selectedLayer.shapes.push(duplicateShape);
        }
        return updatedLayers;
      });
    }
  };

  const calculatePolygonPoints = (startX, startY, endX, endY) => {
    const length =
      (nextAnnotation[0].points?.length || 0) -
      (nextAnnotation[0].points?.length / 2 > polyPoints ? 2 : 0);
    return [...(nextAnnotation[0].points?.slice(0, length) || []), endX, endY];
  };

  const buildPolygonData = (startX, startY, endX, endY) => ({
    type: "polygon",
    x: startX,
    y: startY,
    width: endX - startX,
    height: endY - startY,
    id: idForPolygon,
    points: calculatePolygonPoints(startX, startY, endX, endY),
  });

  const handleLineDrawing = (e) => {
    const [nextX, nextY] = getStageMousePos(e);

    if (isDrawLine) {
      if (nextLineAnnotation.length === 0) {
        // Start drawing a new line
        setNextLineAnnotation([
          {
            type: "line",
            points: [nextX, nextY, nextX, nextY], // Initialize with the first point (start)
            id: idForLine, // New unique ID for each line
            stroke: "#000000",
            strokeWidth: 3,
            isLocked: false,
          },
        ]);
      } else {
        // Dynamically add new points to the line as the mouse moves for smoothness
        setNextLineAnnotation((prev) => {
          const newLine = {
            ...prev[0], // Copy the existing line object
            points: [...prev[0].points, nextX, nextY], // Append new point
          };

          // Check if the line now has enough points (two points to complete)
          if (newLine.points.length >= 4) {
            // Ensure minimum length of line
            // Deactivate line drawing mode after the second point is set
            setIsDrawLine(false);
            setCurrentTool(null);

            // Add the completed line to permanent storage (e.g., layers, shapes, etc.)
            setLayers((prevLayers) => {
              const updatedLayers = [...prevLayers]; // Copy existing layers
              const activeLayerIndex = updatedLayers.findIndex(
                (layer) => layer.id === activeLayerId
              );
              if (activeLayerIndex > -1) {
                // Add new shape to the active layer
                updatedLayers[activeLayerIndex].shapes.push(newLine);
              }
              return updatedLayers;
            });

            // Reset annotation state for next line
            setNextLineAnnotation([]); // Clear the current line annotation
            setIdForLine(nanoid()); // Generate a new ID for the next line
          }

          return [newLine]; // Update state with the new line object
        });
      }
    } else {
      // If not drawing, reset everything
      setNextLineAnnotation([]);
      setIsDrawLine(false);
      setCurrentTool(null);
      setIdForLine(nanoid()); // Prepare for a new line drawing session
    }
  };

  const handlePolygonDrawing = (e) => {
    const [nextX, nextY] = getStageMousePos(e);
    if (nextAnnotation.length === 0 && isDrawPoly === true) {
      const data = {
        type: "polygon",
        x: nextX,
        y: nextY,
        width: 0,
        height: 0,
        id: idForPolygon,
        rotation: 0,
        color: "#BF0000",
        strokeWidth: 3,
        points: [nextX, nextY],
        isLocked: false,
        site_id: activeMarker?.site_id,
        building_id: activeMarker?.id,
      };
      setNextAnnotation([data]);
    } else if (isDrawPoly) {
      const nextPoints = (nextAnnotation[0] && nextAnnotation[0].points) || [];
      if (polyIsOverStart) {
        if (polyPoints <= 2) return;
        const updatedLayers = layers.map((layer) => {
          if (layer.id === activeLayerId) {
            const updatedShapes = layer.shapes.map((shape) => {
              if (shape.id === idForPolygon && shape.type === "polygon") {
                return {
                  ...shape,
                  isDone: true,
                };
              }
              return shape;
            });
            return {
              ...layer,
              shapes: updatedShapes,
            };
          }
          return layer;
        });

        setLayers(updatedLayers);
        setNextAnnotation([]);
        updatePolyPoints(1);
        setPolyIsOverStart(false);
        setIdForPolygon(nanoid());
      } else {
        const points = [
          ...(nextPoints.slice(0, nextPoints.length - 2) || []),
          nextX,
          nextY,
        ];
        setNextAnnotation((prev) => [{ ...prev[0], points }]);
        updatePolyPoints((prev) => prev + 1);
      }
    }
  };

  const handleStageMouseMove = (e) => {
    const stage = e.target?.getStage();
    if (!stage) return;
    const [currentX, currentY] = getStageMousePos(e);
    if (currentTool === "line" && isDrawLine) {
      setNextLineAnnotation((prev) => {
        if (prev.length === 1) {
          const updatedAnnotation = { ...prev[0] };
          const [startX, startY] = updatedAnnotation.points.slice(0, 2);
          updatedAnnotation.points = [startX, startY, currentX, currentY];
          return [updatedAnnotation];
        }
        return prev;
      });
    } else if (currentTool === "polygon" && nextAnnotation.length === 1) {
      const startX = nextAnnotation[0].x;
      const startY = nextAnnotation[0].y;
      const updatedPolygonData = buildPolygonData(
        startX,
        startY,
        currentX,
        currentY
      );
      setNextAnnotation([updatedPolygonData]);
    }
  };

  const handleStageMouseUp = (e) => {
    if (e.evt.button === 2) {
      setNextAnnotation([]);
      setNextLineAnnotation([]);
      setIsDrawPoly(false);
      setIsDrawLine(false);
      setCurrentTool(null);
      setIdForLine(nanoid());
      return;
    }
    if (currentTool === "polygon") return;
    if (currentTool === "line") return;
    const stage = e.target?.getStage();
  };
  const handelStageMouseDown = (e) => {
    if (currentTool === "line") {
      handleLineDrawing(e);
    } else if (currentTool === "polygon") {
      handlePolygonDrawing(e);
    }
  };

  //TODO::Polygon UseEffect open in later after line fix
  useEffect(() => {
    if (!isDrawPoly || nextAnnotation.length === 0) {
      return;
    }
    buffer.current.push(...nextAnnotation);
    const handleBuffer = () => {
      if (buffer.current.length > 0) {
        const batchSize = 1000;
        buffer.current = buffer.current.slice(batchSize);
        handelAddPolygonToLayer(activeLayerId, nextAnnotation[0]);
        setTimeout(handleBuffer, 1000000);
      }
    };

    handleBuffer();
  }, [nextAnnotation, activeLayerId]);

  useEffect(() => {
    if (!isDrawLine || nextLineAnnotation.length === 0) {
      return;
    }
    buffer.current.push(...nextLineAnnotation);
    const handleBuffer = () => {
      if (buffer.current.length > 0) {
        const batchSize = 1000;
        buffer.current = buffer.current.slice(batchSize);
        handelAddLineToLayer(activeLayerId, nextLineAnnotation[0]);

        setTimeout(handleBuffer, 1000000);
      }
    };

    handleBuffer();
  }, [nextLineAnnotation, activeLayerId]);

  useEffect(() => {
    if (horizontalRuler) {
      horizontalRuler.scroll(position.x);
      horizontalRuler.zoom = scale * 100;
    }
    if (verticalRuler) {
      verticalRuler.scroll(position.y);
      verticalRuler.zoom = scale * 100;
    }
  }, [scale, position]);

  const handelClearLayer = () => {
    handelAddDefaultLayer();
  };

  useEffect(() => {
    if (!activeMarker) return;
    const layersData = activeMarker.floor_marking_layers?.layers?.[0] || null;
    setLayers((prevLayers) => {
      return prevLayers.map((layer) => {
        let combinedShapes = layersData
          ? [...layersData.shapes]
          : [...layer.shapes];
        const otherShapes = combinedShapes.filter(
          (shape) => shape.type !== "image"
        );
        return {
          ...layer,
          name: activeMarker.name,
          building_id: layersData ? layersData.building_id : activeMarker.id,
          shapes: [
            ...(floorImageObj ? [floorImageObj] : []),
            ...otherShapes,
          ].filter(Boolean),
        };
      });
    });
  }, [activeLayerId, activeMarker, floorImageObj]);

  /**
   * Floor Rack Handler
   */

  const handelLoadRegularImageObjectURL = (dataUrl,type) => {
    return new Promise((resolve, reject) => {
      let image = new window.Image();
      image.src = dataUrl;

      image.addEventListener("load", () => {
        const rack = handelAddObjectOnFloorPlan(dataUrl,type);
        resolve(rack);
      });

      image.addEventListener("error", () => {
        console.error("Failed to load image.");
        reject("Failed to load image");
      });
    });
  };
  const handelAddObjectOnFloorPlan = (image,type) => {
    let newImageObj = {
      x: 100,
      y: 100,
      image: null,
      id: nanoid(),
      type: "image",
      isLocked: false,
      image_type: `${"floor_plan_object_"}${type}`,
      draggable: true,
      object_type: type
    };
    newImageObj.image = image;
    newImageObj.width = image?.width;
    newImageObj.height = image?.height

    return newImageObj;
  };

  /**Rack Items Handel */
  const handelLoadRegularRackItemURL = (dataUrl) => {
    return new Promise((resolve, reject) => {
      let image = new window.Image();
      image.src = dataUrl;

      image.addEventListener("load", () => {
        const width = image.width;  
        const height = image.height;
  
        const rack = handelAddRackItemInRack(dataUrl,width,height);
        resolve(rack);
      });

      image.addEventListener("error", () => {
        console.error("Failed to load image.");
        reject("Failed to load image");
      });
    });
  };

  const handelAddRackItemInRack = (image,width,height) => {
    let newImageObj = {
      x: 100,
      y: 100,
      image: null,
      id: nanoid(),
      type: "image",
      isLocked: false,
      image_type: "floor_plan_object_rack_item",
      object_type: _objectType?.RACK_ITEM,
      draggable: true
    };
    
    newImageObj.image = image;
    newImageObj.width = width;
    newImageObj.height = height
    return newImageObj;
  };



  return (
    <ViewerContext.Provider
      value={{
        currentTool,
        viewportRef,
        isDrawPoly,
        scale,
        setScale,
        position,
        activeLayerId,
        setPosition,
        setupViewer,
        handelResetView,
        handleWheel,
        handleDragMove,
        isViewerSetupDone,
        createRuler,
        vRulerRef,
        hRulerRef,
        handleStageBackgroundColor,
        stageBackgroundColor,
        handelAddLayer,
        layers,
        handelAddShapeToLayer,
        selectedShape,
        setSelectedShape,
        handelDeselectObject,
        handelLoadRegularImage,
        handelLoadRegularImageURL,
        handelLoadSVGImage,
        handelSetActiveLayerId,
        handelObjectBackToFrontViceVersa,
        handleContextMenu,
        updateShapeLockStatus,
        contextMenuVisible,
        setContextMenuVisible,
        contextMenuPosition,
        handelDeleteObject,
        handelCreateDuplicateObject,
        handelUpdateShape,
        setPolyIsOverStart,
        setIsDrawPoly,
        handleStageMouseUp,
        handleStageMouseMove,
        handelStageMouseDown,
        handelClearLayer,
        setNextAnnotation,
        setCurrentTool,
        updatePolyPoints,
        setIdForPolygon,
        isDrawLine,
        setIsDrawLine,
        saveCanvasState,
        handelAddDefaultLayer,
        isUpdateFloorModelVisible,
        setIsUpdateFloorModelVisible,
        handelUpdateFloorDetails,
        handelLoadRegularImageObjectURL,
        floorData,
        shapesTooltip,
        setShapesTooltip,
        handelAddRackItemInRack,
        handelLoadRegularRackItemURL
      }}
    >
      <div
        ref={viewportRef}
        className="viewer"
        id="network-main-scean-viewport"
      >
        {children}
      </div>
    </ViewerContext.Provider>
  );
};

export const useViewer = () => useContext(ViewerContext);
